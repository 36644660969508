<template>
  <div>
    <CRow>
      <CCol sm="4">
        <CCard style="width: 100%; max-width: 600px;" v-for="product in products">
          <CCardHeader>
            <img style="width: 100%; max-width: 600px;" :src='product.thumb_image'>
          </CCardHeader>
          <CCardBody>
            <CCardTitle>{{product.name}}</CCardTitle>
            <CCardText>
              <i v-if="product.category_name && product.type_name">{{product.category_name}} / {{product.type_name}}</i>
              <span v-if="product.sizes_label"><br />Tallas: {{product.sizes_label}}</span>
              <span style="float: right;">$ {{product.price | currency}}</span>
            </CCardText>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import general from '../../services/general';
import store from '../../store'

export default {
  name: 'FilteredLink',
  components: { },
  data: function () {
		return {
      code: '',
      products: []
    }
  },
  mounted: async function() {
    this.loading();

    this.code = await this.$route.params.code;

    let response = await general.getProductsByLink(this.code);

    if(response.type == "success"){
      this.products = response.data;
    }

    this.loaded();
  },
  methods: {
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
